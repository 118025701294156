@import 'src/styles/mixins';

.footer {
    background-color: $c-background-footer;
    display: flex;
    flex-direction: column;

    @media #{$screen-mobile} {
        padding-bottom: var(--bottom-bar-height);

        &.withAdditionalMargin {
            padding-bottom: 159px;
        }
    }
}

.mainGrid {
    padding-bottom: var(--p-sm);
    padding-top: var(--p-md);
    row-gap: var(--p-md);

    @media #{$screen-mobile} {
        padding-bottom: var(--p-sm-24);
        padding-top: var(--p-sm-24);
        row-gap: var(--p-sm);
    }
}

.nav {
    @media #{$screen-mobile} {
        gap: var(--p-xxs);
    }
}

.mainItem {
    display: flex;
    justify-content: space-between;

    @media #{$screen-mobile} {
        min-height: 32px;
        width: 100%;
    }
}

.mainContentItemIcon {
    color: $c-default-gray-100;
    cursor: pointer;
    display: none;
    height: 24px;
    width: 24px;

    @media #{$screen-mobile} {
        display: block;
    }

    svg {
        height: 24px;
        width: 24px;
    }

    &.isActive {
        transform: rotate(180deg);
    }
}

.mainContentSubList {
    display: flex;
    flex-direction: column;
    gap: var(--p-xs);
    list-style: none;
    margin: 16px 0 0;
    padding: 0;

    @media #{$screen-mobile} {
        display: none;

        &.isActive {
            display: flex;
        }
    }
}

.mainContentSubItem {
    @media #{$screen-mobile} {
        padding: 2px 0 12px;
    }

    a {
        color: $c-default-gray-80;
    }
}

.hotLineBlock {
    @media #{$screen-mobile} {
        margin: 16px 0;
    }
}

.hotLineInner {
    @include fluid-prop-sm(margin-bottom,  24px, 32px);

    display: flex;
    flex-direction: column;
}

.hotLineTitle,
.feedbackTitle {
    @include fluid-prop-sm(margin-bottom,  8px, 16px);
}

.openChatButton {
    cursor: pointer;
    margin-bottom: 8px;
    text-align: left;
    width: fit-content;
}

.social {
    display: flex;
    flex-direction: column;
    gap: var(--p-xs);

    @media #{$screen-mobile} {
        gap: var(--p-xxs);
    }
}

.socialList {
    display: flex;
    gap: 24px;
    list-style: none;
    margin: 0;
    padding: 0;

    @media #{$screen-mobile} {
        gap: var(--p-xxs);
    }
}

.socialItem {
    a {
        align-items: center;
        color: $c-default-gray-100;
        display: flex;
        height: 40px;
        justify-content: center;
        width: 40px;

        @media #{$screen-mobile} {
            height: 32px;
            width: 32px;
        }
    }

    svg,
    img {
        height: 40px;
        width: 40px;

        @media #{$screen-mobile} {
            height: 32px;
            width: 32px;
        }
    }
}

.feedback {
    margin-bottom: 59px;

    @media #{$screen-mobile} {
        margin-bottom: 0;
    }

    .feedbackBtn {
        width: 100%;
    }
}

.copyRightSection {
    border-top: 1px solid $c-secondary-30;

    @media #{$screen-mobile} {
        border-top: none;
    }
}

.copyRightContainer {
    @include container;

    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--p-sm);
    padding-top: var(--p-md);

    @media #{$screen-mobile} {
        align-items: start;
        flex-direction: column-reverse;
        position: relative;

        &::after {
            background-color: $c-secondary-30;
            content: '';
            height: 1px;
            left: 16px;
            position: absolute;
            right: 16px;
            top: 0;
        }
    }

    .copyRightTitle {
        color: $c-secondary-70;
    }

    .payment {
        align-items: center;
        display: flex;
        gap: var(--p-sm);

        @media #{$screen-mobile} {
            align-items: start;
            flex-direction: column;
            gap: var(--p-xs);
            margin-bottom: 32px;
        }
    }
}
