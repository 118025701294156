@import 'src/styles/mixins';

.root {
    background-color: $c-default-white-100;
    border: 1px solid $c-default-white-100;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;

    &.active {
        border-color: $c-secondary-50;
    }
}

.address {
    font-style: normal;
    margin-top: 8px;
}

.scheduleBlock {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}

.openChatButton {
    cursor: pointer;
    width: fit-content;
}

.phones {
    margin-top: 16px;
}
