.jivo-widget-position-mobile_default {
    .__jivoMobileButton {
        margin-bottom: 100px !important;
    }
}

.jivo-widget-position-mobile_high-offset {
    .__jivoMobileButton {
        margin-bottom: 180px !important;
    }
}
