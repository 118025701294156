@import 'src/styles/mixins';

.root {
    cursor: pointer;
    transition: $animation;

    &:hover {
        color: $c-default-gray-80;
    }
}
